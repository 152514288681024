import { Search } from '@material-ui/icons';
import { Error as ErrorIcon } from '@mui/icons-material';
import { Autocomplete, Button, Divider, Grid, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'gatsby-plugin-react-intl';
import { get } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { ErrorResponse } from '../../helpers/api';
import localeData from '../../helpers/locale-data';
import { IStep3, IStepProps } from '../../types/step';
import InputField from '../InputField';
import { Form, FormActions, Header, StepContainer } from './components';

const placeComponents = ['street_address', 'premise', 'subpremise'];

const mapPlaceResult = (place: google.maps.places.PlaceResult): Partial<IStep3> => {
  const locale: Partial<IStep3> = { address: place.name };

  for (const component of place.address_components || []) {
    if (component.types.includes('locality')) {
      locale.city = component.short_name;
    } else if (component.types.includes('postal_code')) {
      locale.postcode = component.short_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      locale.state = component.short_name;
    }
  }

  return locale;
};

const StepAddress = ({ step, onNextClick, onPrevClick }: IStepProps<IStep3>) => {
  const [error, setError] = useState('');
  const [addressSearch, setAddressSearch] = useState('');
  const [addressVisible, setAddressVisible] = useState(!!step.address);
  const [addressSelected, setAddressSelected] = useState<string | google.maps.places.AutocompletePrediction | null>(null);
  const [addressSuggestions, setAddressSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const intl = useIntl();

  const locale = intl.locale as keyof typeof localeData;
  const { addressSchema, countryCode, countryLabel, countryStates, countryValue, timezone } = localeData[locale];

  const formik = useFormik<IStep3>({
    enableReinitialize: true,
    initialValues: { ...step, country: countryValue, locale, timezone },
    validationSchema: addressSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setError('');

      try {
        onNextClick && onNextClick(values);
      } catch (err) {
        let genericError = true;

        // Find field-specific errors and set them
        const errors: ErrorResponse[] = get(err, 'response.data.errors', []);
        if (errors.length) {
          errors.forEach((e) => {
            if (e.meta?.key) {
              formik.setFieldError(e.meta.key, e.title);
            }
          });
          genericError = false;
        }

        setError(
          genericError
            ? 'There was an error submitting your details, please try again later.'
            : 'There are some errors in the form, please correct them to continue.',
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.GATSBY_GOOGLE_PLACES_API_KEY,
    options: {
      componentRestrictions: { country: countryCode },
      input: '',
      types: ['address'],
    },
  });

  useEffect(() => {
    if (placePredictions.length) {
      setAddressSuggestions(placePredictions.filter((x) => x.types.some((y) => placeComponents.includes(y))));
    }
  }, [placePredictions]);

  useEffect(() => {
    const selected = addressSelected as google.maps.places.AutocompletePrediction;
    if (selected?.place_id) {
      placesService?.getDetails({ placeId: selected.place_id }, (place) => {
        if (place) {
          formik.setValues({ ...formik.values, ...mapPlaceResult(place) });
          setAddressSearch('');
          setAddressVisible(true);
        }
      });
    }
  }, [addressSelected]);

  useEffect(() => {
    if (addressSearch?.length > 5) {
      // Require a min length to optimise searches
      getPlacePredictions({ input: addressSearch });
    } else {
      setAddressSuggestions([]);
    }
  }, [addressSearch]);

  const handleOnPrevClick = useCallback(() => onPrevClick?.(formik.values), [formik.values]);

  return (
    <StepContainer>
      <Header>Address</Header>
      <Typography marginBottom={2}>Terrific, as a second step, please enter your current residential address.</Typography>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              color="secondary"
              filterOptions={(x) => x}
              fullWidth
              freeSolo
              getOptionLabel={(x) => x?.description || ''}
              inputValue={addressSearch}
              onInputChange={(e, input) => setAddressSearch(input)}
              onChange={(e, value) => setAddressSelected(value)}
              options={addressSuggestions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoCapitalize="on"
                  autoFocus={!addressVisible}
                  label="Address search"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required={!addressVisible}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {addressVisible ? (
            <>
              <Grid item xs={12} marginY={2}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <InputField autoCapitalize="on" name="address" label="Street address" formik={formik} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <InputField autoCapitalize="on" name="premise" label="Building, apartment or suite..." formik={formik} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField autoCapitalize="on" name="city" label="Suburb" formik={formik} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField name="state" label="State" formik={formik} fullWidth select>
                  {countryStates.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField name="postcode" label="Postcode" formik={formik} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField name="country" label="Country" formik={formik} fullWidth select>
                  <MenuItem key={countryValue} value={countryValue}>
                    {countryLabel}
                  </MenuItem>
                </InputField>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button color="secondary" size="small" sx={{ textTransform: 'none' }} onClick={() => setAddressVisible(true)}>
                Can't find your address?
              </Button>
            </Grid>
          )}

          {error ? (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <ErrorIcon color="error" />
                <Typography color="error">{error}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>

        <FormActions>
          <Button disabled={formik.isSubmitting} onClick={handleOnPrevClick}>
            Back
          </Button>
          <Button disabled={formik.isSubmitting} variant="contained" type="submit">
            Next
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepAddress);
