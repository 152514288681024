import Cookies from 'universal-cookie';

export interface MetaTags {
  fbclid?: string;
  gclid?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
}

const cookies = new Cookies();
const cookieKey = 'META_TAGS';

export const storeMetaTags = (tags: MetaTags) => {
  cookies.set(cookieKey, tags, {
    secure: true,
    sameSite: 'strict',
  });
};

export const getMetaTags = (): MetaTags | undefined => cookies.get(cookieKey);
