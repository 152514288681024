import { Error as ErrorIcon } from '@mui/icons-material';
import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { omitBy, pick } from 'lodash';
import React, { ChangeEvent, memo, useState } from 'react';
import * as yup from 'yup';
import COUNTRY_STATES from '../../constants/countryStates';
import ID_TYPES, { medicareCardColors, medicareCardRefNumbers } from '../../constants/idTypes';
import { callApi, ErrorResponse } from '../../helpers/api';
import { getDateByAge } from '../../helpers/date';
import { getSession } from '../../helpers/session';
import { IStep2, IStepProps } from '../../types/step';
import InputField from '../InputField';
import { Form, FormActions, Header, StepContainer } from './components';
import { driversLicenceCardNumberRequired, driversLicenceExamples } from './components/driversLicence';

const DRIVERS_LICENCE_CARD_NUMBER_ENABLED = yup.boolean().required().validateSync(process.env.GATSBY_DRIVERS_LICENCE_CARD_NUMBER_ENABLED);

// eslint-disable-next-line no-useless-escape
const StringPattern = RegExp(/^[\w\s\'\-]+$/);

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(250)
    .matches(StringPattern, 'First name can only contain letters, spaces, quotes and hyphens')
    .required()
    .label('First name'),
  middleName: yup
    .string()
    .trim()
    .max(250)
    .matches(StringPattern, 'Middle name can only contain letters, spaces, quotes and hyphens')
    .label('Middle name'),
  lastName: yup
    .string()
    .trim()
    .max(250)
    .matches(StringPattern, 'Last name can only contain letters, spaces, quotes and hyphens')
    .required()
    .label('Last name'),
  dob: yup.date().max(getDateByAge(18), 'You must be older than 18 to sign up'),
  identityType: yup.string().oneOf(ID_TYPES.map((x) => x.value)),
  identityNumber: yup
    .string()
    .trim()
    .max(250)
    .when('identityType', (identityType, schema) => {
      switch (identityType) {
        case 'aus-driver-license':
          return schema.label('Licence number');
        case 'aus-medicare':
          return schema.label('Medicare number');
        case 'aus-passport':
          return schema.label('Passport number');
        default:
          return schema.label('ID number');
      }
    }),
  identityState: yup.string().oneOf(COUNTRY_STATES.map((x) => x.value)),
  identityRefNumber: yup
    .string()
    .trim()
    .max(250)
    .when('identityType', (identityType, schema) => {
      switch (identityType) {
        case 'aus-driver-license':
          return schema.label('Licence card number');
        case 'aus-medicare':
          return schema.oneOf(medicareCardRefNumbers).label('Medicare reference number');
        default:
          return schema.label('ID reference number');
      }
    }),
  identityExpiryDate: yup.date().min(new Date(), 'Please enter a valid expiry date'),
  identityColour: yup.string().oneOf(medicareCardColors.map((colour) => colour.value)),
});

const StepIdentity = ({ step, onNextClick, onPrevClick }: IStepProps) => {
  const [error, setError] = useState('');

  const formik = useFormik<IStep2>({
    enableReinitialize: true,
    initialValues: step as IStep2,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const _values = pick(values, ['firstName', 'lastName', 'dob', 'identityType', 'identityNumber']);

      try {
        setError('');
        const session = getSession();

        if (session) {
          await callApi(
            `/sessions/${session.sessionId}/id-verification`,
            'POST',
            { data: { attributes: _values } },
            { 'x-session-token': session.sessionToken },
          );

          onNextClick?.(omitBy(values, (val) => val === ''));
        } else {
          setError('Your sign up session has expired. Please reload the page and try again.');
        }
      } catch (err) {
        setErrors(err as Error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const setErrors = (err: Error) => {
    try {
      if (axios.isAxiosError(err)) {
        const errors: ErrorResponse[] = err.response?.data.errors;
        if (errors?.length) {
          // Set field specific errors by the key
          errors.forEach((error) => formik.setFieldError(error.meta!.key, error.title));
          setError('There are some errors in the form, please correct them to continue.');
        } else {
          throw err;
        }
      } else {
        throw err;
      }
    } catch {
      // Set a generic error message because we cannot get field specific errors
      setError('There was an error submitting your details, please try again later.');
    }
  };

  const handleOnPrevClick = () => onPrevClick?.(formik.values);

  /**
   * Reset identity values when identity type changed.
   * @param event Change event
   */
  const handleIdTypeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setValues((state) => ({
      ...state,
      identityType: event.target.value,
      identityState: '',
      identityNumber: '',
      identityRefNumber: '',
      identityExpiryDate: '',
      identityColour: '',
      agreedToIdCheck: false,
    }));
  };

  return (
    <StepContainer>
      <Header>ID verification</Header>
      <Typography marginBottom={2}>Please enter your details below to verify your identity.</Typography>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <InputField required autoFocus autoCapitalize="on" name="firstName" label="Legal first name" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField name="middleName" autoCapitalize="on" label="Legal middle name" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField required name="lastName" autoCapitalize="on" label="Legal last name" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              required
              type="date"
              name="dob"
              label="Date of birth"
              formik={formik}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              color="secondary"
              error={formik.touched['identityType'] && Boolean(formik.errors['identityType'])}
              fullWidth
              helperText={formik.touched['identityType'] && formik.errors['identityType']}
              InputLabelProps={{ shrink: true }}
              label="ID Type"
              name="identityType"
              onChange={handleIdTypeChanged}
              required
              select
              value={formik.values['identityType']}
              variant="outlined"
            >
              {ID_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {formik.values.identityType === 'aus-driver-license' && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <InputField required name="identityState" label="Licence State" select formik={formik} fullWidth>
                  {COUNTRY_STATES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputField required name="identityNumber" label="Licence number" formik={formik} fullWidth />
              </Grid>
              {DRIVERS_LICENCE_CARD_NUMBER_ENABLED && driversLicenceCardNumberRequired[formik.values.identityState] && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputField required name="identityRefNumber" label="Licence card number" formik={formik} fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    {driversLicenceExamples[formik.values.identityState]}
                  </Grid>
                </>
              )}
            </>
          )}
          {formik.values.identityType === 'aus-medicare' && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <InputField required name="identityNumber" label="Medicare number" formik={formik} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputField required name="identityRefNumber" label="Medicare Ref Number" select formik={formik} fullWidth>
                  {medicareCardRefNumbers.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputField
                  required
                  type="date"
                  name="identityExpiryDate"
                  label="Expiry Date"
                  formik={formik}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputField required name="identityColour" label="Colour" select formik={formik} fullWidth>
                  {medicareCardColors.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
            </>
          )}
          {formik.values.identityType === 'aus-passport' && (
            <Grid item xs={12} sm={12} md={6}>
              <InputField required name="identityNumber" label="Passport number" formik={formik} fullWidth />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox required name="agreedToIdCheck" checked={formik.values.agreedToIdCheck} onChange={formik.handleChange} />}
              label="I confirm that I am authorised to provide any personal information provided to PressPay and consent to the disclosure of my name, residential address and date of birth to a credit reporting body or other organisation (including the document issuer or official records holder) for the purposes of electronically verifying my identity."
            />
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <ErrorIcon color="error" />
                <Typography color="error">{error}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>

        <FormActions>
          <Button disabled={formik.isSubmitting} onClick={handleOnPrevClick}>
            Back
          </Button>
          <Button disabled={formik.isSubmitting} variant="contained" type="submit">
            Next
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepIdentity);
