import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'gatsby-plugin-react-intl';
import React, { memo } from 'react';
import * as yup from 'yup';
import { IStep1B, IStepProps } from '../../types/step';
import { Form, FormActions, Header, StepContainer } from './components';

const TermsOfService = ({ formik }: { formik: ReturnType<typeof useFormik<IStep1B>> }) => {
  const intl = useIntl();
  return (
    <FormControlLabel
      control={<Checkbox required name="agreedToTOS" checked={formik.values.agreedToTOS} onChange={formik.handleChange} />}
      label={
        <Typography>
          I have read, understood and agree to the&nbsp;
          <Link
            target="_blank"
            rel="noreferrer"
            href={intl.formatMessage({
              description: 'Terms of Service URL',
              defaultMessage: 'https://presspay.com.au/terms-of-service-202309/',
            })}
          >
            Terms of Service
          </Link>
          .
        </Typography>
      }
    />
  );
};

const DDRSA = ({ formik }: { formik: ReturnType<typeof useFormik<IStep1B>> }) => {
  const intl = useIntl();
  return (
    <FormControlLabel
      control={<Checkbox required name="agreedToDDRSA" checked={formik.values.agreedToDDRSA} onChange={formik.handleChange} />}
      label={
        <Typography>
          I have read, understood and agree to the&nbsp;
          <Link
            target="_blank"
            rel="noreferrer"
            href={intl.formatMessage({
              description: 'Direct Debit Service Agreement URL',
              defaultMessage: 'https://presspay.com.au/ddrsa-202309/',
            })}
          >
            Direct Debit Service Agreement
          </Link>
          &nbsp;and that the electronically signed copy with my personal and banking details will be sent by email.
        </Typography>
      }
    />
  );
};

const validationSchema = yup.object({
  agreedToTOS: yup.boolean().label('Terms of Service URL').required(),
  agreedToDDRSA: yup.boolean().label('Direct Debit Service Agreement').required(),
});

const StepConsent = ({ onNextClick, onPrevClick }: IStepProps<IStep1B>) => {
  const formik = useFormik({
    initialValues: {
      agreedToTOS: false,
      agreedToDDRSA: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onNextClick?.(values);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleOnPrevClick = () => onPrevClick?.(formik.values);

  return (
    <StepContainer>
      <Header>How PressPay works</Header>
      <Typography marginBottom={2}>Before you continue, it's important you understand how PressPay works.</Typography>
      <ul>
        <Typography marginBottom={2} component="li">
          Withdrawals to access your pay in advance may be made by sending an SMS text message to the number listed on our website, or by
          completing an online request from the Customer Dashboard.
        </Typography>
        <Typography marginBottom={2} component="li">
          Withdrawals may only be made against your available balance and must be repaid by direct debit on the Repayment Date.
        </Typography>
        <Typography marginBottom={2} component="li">
          The Repayment Date will align with the Pay Cycle by which you receive your regular income, this may be weekly, fortnightly or
          monthly.
        </Typography>
        <Typography marginBottom={2} component="li">
          You can make multiple withdrawals during your Pay Cycle, but all withdrawn amounts must be repaid in full on the Repayment Date.
        </Typography>
        <Typography marginBottom={2} component="li">
          Should you fail to make the required Repayments, we may suspend or terminate your access to our services and take further action
          until your outstanding balance is repaid.
        </Typography>
      </ul>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TermsOfService formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <DDRSA formik={formik} />
          </Grid>
        </Grid>
        <FormActions>
          <Button disabled={formik.isSubmitting} onClick={handleOnPrevClick}>
            Back
          </Button>
          <Button disabled={formik.isSubmitting} variant="contained" type="submit">
            Continue
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepConsent);
