export default [
  { value: 'aus-driver-license', label: 'Drivers licence' },
  { value: 'aus-passport', label: 'Passport' },
  { value: 'aus-medicare', label: 'Medicare card' },
];

export const medicareCardColors = [
  { value: 'G', label: 'Green' },
  { value: 'B', label: 'Blue' },
  { value: 'Y', label: 'Yellow' },
];

export const medicareCardRefNumbers = ['1', '2', '3', '4', '5', '6'];
