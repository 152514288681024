import { Error as ErrorIcon } from '@mui/icons-material';
import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useState } from 'react';
import Countdown from 'react-countdown';
import * as yup from 'yup';
import { callApi } from '../../helpers/api';
import { getSession } from '../../helpers/session';
import { IStep1A, IStepProps } from '../../types/step';
import InputField from '../InputField';
import { Form, FormActions, Header, StepContainer } from './components';

const validationSchema = yup.object({
  mobileNumberVerificationCode: yup.string().label('Verification code').length(6).required(),
});

const StepVerification = ({ step, onNextClick, onPrevClick }: IStepProps<IStep1A>) => {
  const [error, setError] = useState('');
  const [countdownDate, setCountdownDate] = useState(step.countdownDate || Date.now() + 60000);

  const formik = useFormik({
    initialValues: {
      mobileNumberVerificationCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      try {
        setError('');
        const session = getSession();

        if (session) {
          const { data } = await callApi(
            `/sessions/${session.sessionId}/verification`,
            'POST',
            { data: { attributes: values } },
            { 'x-session-token': session.sessionToken },
          );

          if (data.data.attributes.verified) {
            onNextClick?.({
              countdownDate: Date.now() + 60000,
              mobileNumberVerified: true,
            });
          } else {
            setFieldError('mobileNumberVerificationCode', 'Invalid verification code. Please try again.');
          }
        } else {
          setError('Your sign up session has expired. Please reload the page and try again.');
        }
      } catch (err) {
        setError('The code is invalid or has expired. Please request a new code and try again.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleOnPrevClick = () => onPrevClick?.({ countdownDate, mobileNumberVerified: false });

  const handleResendCodeClick = async () => {
    try {
      setError('');
      const session = getSession();

      if (session) {
        await callApi(
          `/sessions/${session.sessionId}/verification`,
          'POST',
          { data: { attributes: {} } },
          { 'x-session-token': session.sessionToken },
        );
        setCountdownDate(Date.now() + 60000);
      } else {
        setError('Your sign up session has expired. Please reload the page and try again.');
      }
    } catch (err) {
      // Set a generic error message because we cannot get field specific errors
      setError('Could not send the code to your mobile number. Please check your number and try again.');
    } finally {
      formik.resetForm();
    }
  };

  return (
    <StepContainer>
      <Header>Verification</Header>
      <Typography marginBottom={2}>Please verify your mobile number by entering the code we sent to you via SMS.</Typography>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              required
              name="mobileNumberVerificationCode"
              label="Verification code"
              formik={formik}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Countdown
              date={countdownDate}
              key={countdownDate}
              renderer={({ completed, seconds }) => {
                if (completed) {
                  return (
                    <Typography fontSize="small">
                      Did not receive the code?&nbsp;
                      <Link fontSize="small" color="secondary" component="button" onClick={handleResendCodeClick}>
                        Resend code
                      </Link>
                      .
                    </Typography>
                  );
                }

                return (
                  <Typography fontSize="small">Did not receive the code? You can request the code again in {seconds} seconds.</Typography>
                );
              }}
            />
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <ErrorIcon color="error" />
                <Typography color="error">{error}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
        <FormActions>
          <Button disabled={formik.isSubmitting} onClick={handleOnPrevClick}>
            Back
          </Button>
          <Button disabled={formik.isSubmitting} variant="contained" type="submit">
            Next
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepVerification);
