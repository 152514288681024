import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Help as HelpIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import ACT from '../../../assets/drivers-licence-examples/act.png';
import NSW from '../../../assets/drivers-licence-examples/nsw.png';
import NT1 from '../../../assets/drivers-licence-examples/nt-1.png';
import NT2 from '../../../assets/drivers-licence-examples/nt-2.png';
import QLD1 from '../../../assets/drivers-licence-examples/qld-1.png';
import QLD2 from '../../../assets/drivers-licence-examples/qld-2.png';
import SA from '../../../assets/drivers-licence-examples/sa.png';
import TAS from '../../../assets/drivers-licence-examples/tas.png';
import VIC from '../../../assets/drivers-licence-examples/vic.png';
import WA from '../../../assets/drivers-licence-examples/wa.png';

export const ActDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="ACT driver's licence card number location." />
    <CardMedia component="img" height="250px" image={ACT} sx={{ objectFit: 'contain' }} alt="ACT" />
  </Card>
);

export const NswDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="NSW driver's licence card number location." />
    <CardMedia component="img" height="250px" image={NSW} sx={{ objectFit: 'contain' }} alt="NSW" />
  </Card>
);

export const NtDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="NT driver's licence card number location." />
    <CardMedia component="img" height="250px" src={NT2} sx={{ objectFit: 'contain' }} alt="NT" />
    <CardContent>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Licence issued before 10 December 2020</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardMedia component="img" height="250px" src={NT1} sx={{ objectFit: 'contain' }} alt="NT" />
        </AccordionDetails>
      </Accordion>
    </CardContent>
  </Card>
);

export const QldDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="QLD driver's licence card number location." />
    <CardMedia component="img" height="250px" src={QLD2} sx={{ objectFit: 'contain' }} alt="QLD" />
    <CardContent>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Licence issued before June 2019</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardMedia component="img" height="250px" src={QLD1} sx={{ objectFit: 'contain' }} alt="QLD" />
        </AccordionDetails>
      </Accordion>
    </CardContent>
  </Card>
);

export const SaDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="SA driver's licence card number location." />
    <CardMedia component="img" height="250px" image={SA} sx={{ objectFit: 'contain' }} alt="SA" />
  </Card>
);

export const TasDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="TAS driver's licence card number location." />
    <CardMedia component="img" height="250px" image={TAS} sx={{ objectFit: 'contain' }} alt="TAS" />
  </Card>
);

export const VicDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="VIC driver's licence card number location." />
    <CardMedia component="img" height="250px" image={VIC} sx={{ objectFit: 'contain' }} alt="VIC" />
  </Card>
);

export const WaDriversLicenceExample = () => (
  <Card>
    <CardHeader avatar={<HelpIcon color="info" />} title="WA driver's licence card number location." />
    <CardMedia component="img" height="250px" image={WA} sx={{ objectFit: 'contain' }} alt="WA" />
  </Card>
);

export const driversLicenceCardNumberRequired: Record<string, boolean> = {
  ACT: true,
  NSW: true,
  NT: true,
  QLD: true,
  SA: true,
  TAS: true,
  VIC: true,
  WA: true,
};

export const driversLicenceExamples: Record<string, JSX.Element> = {
  ACT: <ActDriversLicenceExample />,
  NSW: <NswDriversLicenceExample />,
  NT: <NtDriversLicenceExample />,
  QLD: <QldDriversLicenceExample />,
  SA: <SaDriversLicenceExample />,
  TAS: <TasDriversLicenceExample />,
  VIC: <VicDriversLicenceExample />,
  WA: <WaDriversLicenceExample />,
};
