import * as yup from 'yup';

export default {
  'en-AU': {
    addressSchema: yup
      .object({
        address: yup.string().label('Address').required(),
        premise: yup.string().label('Premise'),
        city: yup.string().label('Suburb').required(),
        country: yup.string().label('Country').required(),
        postcode: yup
          .string()
          .label('Postcode')
          .required()
          .matches(/^\d{4}$/, 'Postcode must be a four digit number'),
        state: yup.string().label('State').required(),
      })
      .required(),
    countryCode: 'au',
    countryLabel: 'Australia',
    countryStates: ['ACT', 'JBT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
    countryValue: 'AUS',
    mobilePrefix: '+61',
    mobileRegExp: /^0?([45]\d{8})$/,
    timezone: 'Australia/Sydney',
  },
};
