import { Typography } from '@material-ui/core';
import { Container } from '@mui/material';
import axios from 'axios';
import { PageProps } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { omit, reduce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import StepAddress from '../components/SignUpSteps/address';
import StepBankData from '../components/SignUpSteps/bank-data';
import StepConsent from '../components/SignUpSteps/consent';
import StepEmployment from '../components/SignUpSteps/employment';
import StepIdentity from '../components/SignUpSteps/identity';
import StepStart from '../components/SignUpSteps/start';
import StepVerification from '../components/SignUpSteps/verification';
import { callApi } from '../helpers/api';
import { getMetaTags, storeMetaTags } from '../helpers/meta-tags';
import { getSession } from '../helpers/session';
import { IStep1A, IStep1B, IStep3, IStepProps, StepDataCombined } from '../types/step';

interface ISteps {
  [index: number]: IStepProps['step'];
}

const mobileNumberRegex = /^(\+?614|04)(\d{8})$/;
const dobRegex = /^(\d{4}\-\d{2}\-\d{2})$/;

const stepsInitialValue = {
  1: {
    agreedToPP: false,
    email: '',
    mobileNumber: '',
    mobileNumberPrefix: '+61',
  },
  1.1: {
    countdownDate: 0,
    mobileNumberVerified: false,
  },
  1.2: {
    agreedToTOS: false,
    agreedToDDRSA: false,
  },
  2: {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    identityType: '',
    identityState: '',
    identityNumber: '',
    identityRefNumber: '',
    identityExpiryDate: '',
    identityColour: '',
    agreedToIdCheck: false,
  },
  3: {
    address: '',
    premise: '',
    city: '',
    country: '',
    postcode: '',
    state: '',
  },
  4: {
    incomeType: '',
    incomeFrequency: '',
    lastIncomeAmount: '',
    lastIncomeDate: '',
  },
  5: {},
};

export default ({ location }: PageProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState<ISteps>(stepsInitialValue);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Capture meta tags
    storeMetaTags({
      fbclid: params.get('fbclid') || undefined,
      gclid: params.get('gclid') || undefined,
      utmCampaign: params.get('utm_campaign') || undefined,
      utmContent: params.get('utm_content') || undefined,
      utmMedium: params.get('utm_medium') || undefined,
      utmSource: params.get('utm_source') || undefined,
      utmTerm: params.get('utm_term') || undefined,
    });

    // Pre-fill form from query string
    setSteps({
      ...steps,
      [1]: {
        ...steps[1],
        email: params.get('email') || '',
        mobileNumber: (params.get('mobileNumber') || '').match(mobileNumberRegex)?.[2] || '',
      },
      [2]: {
        ...steps[2],
        firstName: params.get('firstName') || '',
        middleName: params.get('middleName') || '',
        lastName: params.get('lastName') || '',
        dob: (params.get('dob') || '').match(dobRegex)?.[1] || '',
      },
    });
  }, [location]);

  useEffect(() => {
    const screen = `sign-up-${currentStep}`;
    firebase.analytics().logEvent('screen_view', {
      firebase_screen: screen,
      firebase_screen_class: screen,
    });
  }, [currentStep]);

  const Component = useMemo(
    () => (props: IStepProps) => {
      switch (currentStep) {
        case 1:
          return <StepStart {...props} />;
        case 1.1:
          return <StepVerification {...(props as IStepProps<IStep1A>)} />;
        case 1.2:
          return <StepConsent {...(props as IStepProps<IStep1B>)} />;
        case 2:
          return <StepIdentity {...props} />;
        case 3:
          return <StepAddress {...(props as IStepProps<IStep3>)} />;
        case 4:
          return <StepEmployment {...props} />;
        case 5:
          return <StepBankData {...props} />;
        default:
          return null;
      }
    },
    [currentStep],
  );

  const submitUserData = async () => {
    try {
      const metaTags = getMetaTags() || {};
      const session = getSession();

      if (!session) {
        throw new Error('Invalid user session');
      }

      // merge all values into a single object
      const allValues = Object.entries(steps).reduce((acc, [, value]) => ({ ...acc, ...value }), {} as { [key: string]: any });

      setSubmitting(true);

      const attributes: Partial<StepDataCombined> = {
        ...metaTags,
        ...omit(allValues, [
          'agreedToDDRSA',
          'agreedToIdCheck',
          'agreedToPP',
          'agreedToTOS',
          'countdownDate',
          'email',
          'mobileNumber',
          'mobileNumberPrefix',
          'mobileNumberVerified',
          'receiveRegularIncome',
        ]),
      };

      await callApi(`/sessions/${session.sessionId}/user`, 'post', { data: { attributes } }, { 'x-session-token': session.sessionToken });
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(
          reduce(
            error.response.data.errors,
            (acc, cur) => {
              acc += `${cur.title}\n`;
              return acc;
            },
            '',
          ),
        );
      } else {
        // TODO we don't handle
        throw new Error('Oops. An unknown error has occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleNextStep = async (values: any) => {
    let nextStep = currentStep;
    switch (currentStep) {
      case 1:
        nextStep = 1.1;
        break;
      case 1.1:
        nextStep = 1.2;
        break;
      case 1.2:
        nextStep = 2;
        break;
      default:
        nextStep += 1;
        break;
    }

    setSteps({ ...steps, [currentStep]: values });
    setCurrentStep(nextStep);
  };

  const handlePrevStep = (values: any) => {
    let nextStep = currentStep;
    switch (currentStep) {
      case 1:
      case 1.1:
      case 1.2:
        nextStep = 1;
        break;
      case 2:
        nextStep = 1.2;
        break;
      default:
        nextStep -= 1;
        break;
    }

    setSteps({ ...steps, [currentStep]: values });
    setCurrentStep(nextStep);
  };

  if (!Component) return null;
  return (
    <>
      <Component
        currentStep={currentStep}
        onNextClick={handleNextStep}
        onPrevClick={handlePrevStep}
        setErrorMessage={setErrorMessage}
        step={steps[currentStep]}
        submitting={submitting}
        submitUserData={submitUserData}
      />

      {errorMessage && (
        <Container maxWidth="md" sx={{ padding: 3, textAlign: 'center' }}>
          <Typography>
            One or more errors have occurred.
            <br />
            Please review them and try again.
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
        </Container>
      )}
    </>
  );
};
