import { Error as ErrorIcon } from '@mui/icons-material';
import { Button, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useState } from 'react';
import * as yup from 'yup';
import WAGE_FREQUENCIES from '../../constants/wageFrequencies';
import WAGE_TYPES from '../../constants/wageTypes';
import { callApi } from '../../helpers/api';
import { getSession } from '../../helpers/session';
import { IStep3, IStepProps } from '../../types/step';
import InputField from '../InputField';
import { Form, FormActions, Header, StepContainer } from './components';

const validationSchema = yup.object({
  incomeType: yup.string(),
  incomeFrequency: yup.string(),
  lastIncomeAmount: yup.number(),
  lastIncomeDate: yup.date().max(new Date(), 'Please enter a valid last pay date'),
});

const StepEmployment = ({ step, onNextClick, onPrevClick }: IStepProps) => {
  const [error, setError] = useState('');

  const formik = useFormik<IStep3>({
    initialValues: step as IStep3,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setError('');
        const session = getSession();

        if (session) {
          await callApi(
            `/sessions/${session.sessionId}/employment-detail`,
            'POST',
            { data: { attributes: values } },
            { 'x-session-token': session.sessionToken },
          );
        } else {
          setError('Your sign up session has expired. Please reload the page and try again.');
        }
      } catch (err) {
        // Set a generic error message because we cannot get field specific errors
        setError('There was an error submitting your details, please try again later.');
      } finally {
        setSubmitting(false);
        onNextClick && onNextClick(values);
      }
    },
  });

  const handleOnPrevClick = () => onPrevClick && onPrevClick(formik.values);

  return (
    <StepContainer>
      <Header>Employment details</Header>
      <Typography marginBottom={2}>
        You're almost done! PressPay helps those with regular income get access to a portion of that income early, so please fill in your
        details accurately below.
      </Typography>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <InputField required autoFocus select name="incomeType" label="Wage type" formik={formik} fullWidth>
              {WAGE_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField required select name="incomeFrequency" label="Wage frequency" formik={formik} fullWidth>
              {WAGE_FREQUENCIES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              required
              type="number"
              name="lastIncomeAmount"
              label="Last wage amount"
              formik={formik}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputProps={{ min: 0 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              required
              name="lastIncomeDate"
              label="Date of last pay"
              type="date"
              formik={formik}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <ErrorIcon color="error" />
                <Typography color="error">{error}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
        <FormActions>
          <Button disabled={formik.isSubmitting} onClick={handleOnPrevClick}>
            Back
          </Button>
          <Button disabled={formik.isSubmitting} variant="contained" type="submit">
            Next
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepEmployment);
