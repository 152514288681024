import Cookies from 'universal-cookie';

export interface Session {
  id: string;
  attributes: {
    expiresAt: number;
    token: string;
  };
}

export interface UserSession {
  sessionId: string;
  sessionToken: string;
}

const cookies = new Cookies();
const cookiesKey = 'USER_SESSION';

export const getSession = (): UserSession => cookies.get(cookiesKey);

export const storeSession = (newSession: Session) => {
  cookies.set(
    cookiesKey,
    { sessionId: newSession.id, sessionToken: newSession.attributes.token },
    { secure: true, expires: new Date(newSession.attributes.expiresAt * 1000), sameSite: 'strict' },
  );
};
