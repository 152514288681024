import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import React, { memo, useEffect, useState } from 'react';
import { callApi } from '../../helpers/api';
import { getSession, UserSession } from '../../helpers/session';
import { IBankSession } from '../../types/bank-session';
import { IStepProps } from '../../types/step';
import IFrame from '../bank-frame';
import { FormActions, Header, StepContainer } from './components';

const COMPLETE_PAGE_URL: string = process.env.GATSBY_COMPLETE_PAGE_URL!;

const useBankSession = (session?: UserSession) => {
  const [bankSession, setBankSession] = useState<undefined | IBankSession>(undefined);

  const refreshBankSession = (s?: UserSession) => {
    if (s) {
      callApi(`/sessions/${s.sessionId}/bank-data`, 'post', undefined, {
        'x-session-token': s.sessionToken,
      }).then((res) => setBankSession(res.data));
    }
  };

  useEffect(() => refreshBankSession(session), [session?.sessionId]);

  return { bankSession, refreshBankSession };
};

const StepBankData = ({ onPrevClick, submitUserData }: IStepProps) => {
  const session = getSession();
  const { bankSession, refreshBankSession } = useBankSession(session);
  const [bankLoginErrorMessage, setBankLoginErrorMessage] = useState('');
  const [userSubmitErrorMessage, setUserSubmitErrorMessage] = useState('');

  const handleSubmitUserData = async () => {
    try {
      await submitUserData();

      const screen = 'sign-up-submitted';
      firebase.analytics().logEvent('screen_view', {
        firebase_screen: screen,
        firebase_screen_class: screen,
      });

      await navigate(COMPLETE_PAGE_URL);
    } catch (error) {
      setUserSubmitErrorMessage(error instanceof Error ? error.message : 'Oops. An unknown error has occurred, please try again.');
    }
  };

  useEffect(() => {
    if (!bankSession) return;

    const bankDataEventHandler = (event: MessageEvent) => {
      if (event.origin !== bankSession.data.attributes.origin) return;

      if (event.data) {
        const { status, errorText } = JSON.parse(event.data);

        if (status === 'COMPLETE') {
          void handleSubmitUserData();
        } else {
          // all other errors, display error dialogue
          setBankLoginErrorMessage(errorText || 'Oops. An unknown error has occurred, please try again.');
        }
      }
    };

    window.addEventListener('message', bankDataEventHandler, false);

    return () => {
      window.removeEventListener('message', bankDataEventHandler);
    };
  }, [bankSession]);

  const handleRetryClick = () => {
    setBankLoginErrorMessage('');
    refreshBankSession(session);
  };

  const handleUserSubmitRetryClick = () => {
    setUserSubmitErrorMessage('');
    void handleSubmitUserData();
  };

  return (
    <StepContainer>
      <Header>Bank statement</Header>
      <Typography marginBottom={2}>
        Final Step! A fast and secure way to provide PressPay with viewing access to your bank statements is via a trusted third-party
        service provider, Illion Open Data Solutions Pty Ltd. PressPay will only access the information required to assess your eligibility
        for our services.
      </Typography>

      {bankSession ? (
        <IFrame src={bankSession?.data?.attributes?.url} />
      ) : (
        <FormActions>
          <CircularProgress />
        </FormActions>
      )}

      <FormActions>
        <Button onClick={() => onPrevClick({})}>Back</Button>
      </FormActions>

      <Dialog title="Oh uh!" open={!!bankLoginErrorMessage}>
        <DialogTitle>Oh Uh!</DialogTitle>
        <DialogContent sx={{ whiteSpace: 'pre-wrap', marginTop: 2 }}>{bankLoginErrorMessage}</DialogContent>
        <DialogActions>
          <Button onClick={onPrevClick}>Back</Button>
          <Button autoFocus variant="contained" color="secondary" onClick={handleRetryClick}>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog title="Oh uh!" open={!!userSubmitErrorMessage}>
        <DialogTitle>Oh Uh!</DialogTitle>
        <DialogContent>
          <Typography>
            One or more errors have occurred.
            <br />
            Please review them and try again.
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap', marginTop: 2 }} color="error">
            {userSubmitErrorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onPrevClick}>Back</Button>
          <Button autoFocus variant="contained" color="secondary" onClick={handleUserSubmitRetryClick}>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
    </StepContainer>
  );
};

export default memo(StepBankData);
